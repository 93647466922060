import './App.css';
import Router from "./Router";

function App() {
  return (
    <div >
      <Router />
    </div>
  );
}

export default App;
